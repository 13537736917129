body {
    font-family: $font-family-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: black;
    background: url(../images/charte/fond-de-page.jpg) no-repeat center fixed ;
	background-size: cover;
  }
  
  p{
    @media (max-width:1280px) {
         font-size:14px
    }
   }
  
  .font2{
    font-family: $headings-font-family;
  }
  
  .page-header {
      margin: 50px 0;
      @extend .title_center;
  }

.bg-1{
    @media (max-width:$screen-sm-min) {
        h1{
            text-shadow: 2px 2px 1px black,
                0px 0px 10px black;
        }
    }
}
  
  .btn, a, button {
      transition: .5s;
              outline-style: none;
          outline-width: 0;
          outline-color: transparent;
  
      &:hover,
      &:focus,
      &:active {
          outline-style: none;
          outline-width: 0;
          outline-color: transparent;
      }
  }
  
  .btn{
      line-height:1.5 !important;
  }
  
  .footer {
      text-align: center;
      background-color: white;
      font-size: $font-size-base;
      color: black;
      min-height: 25rem;
      display: flex;
      align-items: center;
  
      a {
          color: black;
  
          &:hover {
              color: black;
          }
      }
      
        .footer_address{
          color:black;
      }
      @media(min-width: $screen-sm-min) {
          text-align: left;
      }
  }
  
  ////////////// boutons
  
  .btn-fleche {
      @extend .btn-accueil;
      background: $brand-primary;
      white-space: normal;
      position: relative;
      padding: 10px 50px;
      border: 2px solid;
      color: white;
      font-weight: bold;
      border-radius: 50px;
      border: solid 1px $brand-primary;
      //margin-bottom: 50px;
      -webkit-transition: all .2s ease-out;
      transition: all .2s ease-out;
  
  
      &:hover {
          background: white;
          color: $brand-primary;
          border-radius: 50px;
          border: solid 1px $brand-primary;
          padding-right: 70px;
      }
  
      &::after {
          position: absolute;
          margin-left: 20px;
          content: "\f061";
          font-family: fontAwesome;
          color: $brand-primary;
          -webkit-transition: all .8s ease-out .0s;
          transition: all .8s ease-out .0s;
      }
  }
  
  .btn-fleche-2 {
      @extend .btn-accueil;
      background: white;
      white-space: normal;
      position: relative;
      padding: 10px 50px;
      border: 2px solid;
      color: $brand-primary;
      font-weight: bold;
      border-radius: 50px;
      border: solid 1px $brand-primary;
      //margin-bottom: 50px;
      -webkit-transition: all .2s ease-out;
      transition: all .2s ease-out;
  
      &:hover {
          background: $brand-primary;
          color: white;
          border-radius: 50px;
          border: solid 1px $brand-primary;
          padding-right: 70px;
          -webkit-transition: all .2s ease-out;
          transition: all .2s ease-out;
      }
  
      &::after {
          position: absolute;
          margin-left: 20px;
          content: "\f061";
          font-family: fontAwesome;
          color: white;
          -webkit-transition: all .8s ease-out .0s;
          transition: all .8s ease-out .0s;
      }
  }
  
  .btn-fleche-inverse {
      @extend .btn-fleche;
      border: 2px solid;
      color: $brand-primary;
      border: solid 1px $brand-primary;
  
      &:hover {
          background: $brand-primary;
          color: white;
  
      }
  
      &::after {
          color: white;
      }
  }
  
  .btn-perso {
      @extend .btn-accueil;
      background: $brand-primary;
      color: white;
      border: solid 2px white;
      border-radius: 50px;
      padding: 15px 50px;
      margin: 15px auto;
  
      &:hover {
          background: white;
          color: $brand-primary;
          border-radius: 50px;
          border: solid 2px $brand-primary;
      }
  
      .fa {
          margin-right: 10px;
      }
  }
  
  .btn-perso-inverse {
      background: white;
      color: $brand-primary;
      border: solid 1px $brand-primary;
      border-radius: 50px;
      padding: 15px 50px;
      margin: 15px auto;
  
      .fa {
          margin-right: 10px;
      }
  }
  
  /* ==========================================================================
      Global
      ========================================================================== */
      .title_left {
          @extend .text-left;
          margin: 0 0 20px 0;
          padding: 0 0 25px 0;
          position: relative;
          color: $brand-primary;
          text-transform: uppercase;
          line-height: 1.5;
          font-family: 'Josefin Sans', sans-serif;
          font-weight:bolder;
      
          small {
              display: block;
              line-height: 1.5;
          }
      
          &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 4rem;
              height: 4px;
              background-color: $brand-primary;
          }
      }
      
      .title_left_2 {
          @extend .text-left;
          @extend .title_left;
          color: white;
          font-weight: bolder;
      
          &::after {
              background-color: black;
          }
      }
      
      
      .title_center {
          @extend .text-center;
          margin: 0 0 20px 0;
          padding: 0 0 25px 0;
          position: relative;
          color: $brand-primary;
          line-height: 1.5;
          font-family: 'Josefin Sans', sans-serif;
      
          small {
              display: block;
              line-height: 1.5;
          }
      
          &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 50%;
              width: 4rem;
              height: 2px;
              transform: translateX(-50%);
              background-color: $brand-primary;
          }
      }
      
      
      .frame-1{
          padding:20px;
          margin:20px;
              
          @media (max-width:$screen-xs-min) {
                padding:10px;
                margin:10px;
             }
      }
      
      .frame-2{
          padding:20px;
            
          @media (max-width:$screen-xs-min) {
                padding:10px;
             }
      }
  
      .frame-3{
          margin:20px;
            
          @media (max-width:$screen-xs-min) {
                margin:10px;
             }
      }
  
      .smap {
    
        .map {
            padding-bottom: 500px;
        }
    
        .block1 {
            padding: 3%;
           color: white;
            background: $brand-primary;
        }
    }
  
    .img-rounded{
            border-radius: 15px;
    }
  
    .blockquote-2 {
      padding: 15px 30px;
      margin: 0 0 30px;
      font-size: 16px;
      border-left: 5px solid $brand-primary;
      margin-left:25px;
      background-color: white;
      border-radius:0 15px 15px 0px;
      //   box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  }

.reverse{
    @media (max-width:$screen-sm-min) {
      flex-direction: column-reverse ;
    }
}

  
  //.nav>li>a {
      //padding: 10px 15px;
  
  //	@media (min-width:1280px) {
      //	font-size: 12px;
      //	padding: 10px 10px;
      //}
  //}
  
  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////////

  
  
    
///////////////////////////////////////////// 

.flex{
	display: flex;
	flex-wrap: wrap;
	justify-content: left;
	align-items: center;
}


.divider{
	height: 20px;
	background: url(../images/charte/divider.png) no-repeat center;
	max-width: 400px;
	margin: 10px auto;
	}
	
.divider-2{
	height: 20px;
	background: url(../images/charte/divider-2.png) no-repeat center;
	max-width: 400px;
	margin: 10px auto;
}

.col-gauche {
	color: white;
	.title-left {
		@extend .text-left;
		position: relative;
		padding-bottom: 15px;

		&::after {
			content: '';
			position: absolute;
			left: 20px;
			bottom: 0px;;
			margin-left: -2rem;
			background-color: #fff;
			width: 6rem;
			margin-left: -2rem;
			height: 4px;
			background-color: #ad8a3b;
		}
	}
}


.col-droite {
	
	.title-left {
		@extend .text-left;
		position: relative;
		padding-bottom: 15px;

		&::after {
			content: '';
			position: absolute;
			left: 20px;
			bottom: 0px;;
			margin-left: -2rem;
			background-color: #fff;
			width: 6rem;
			margin-left: -2rem;
			height: 4px;
			background-color: #ad8a3b;
		}
	}

}

.title-left-2 {
	@extend .text-left;
	position: relative;
	padding-top: 15px;

	&::after {
		content: '';
		position: absolute;
		left: 20px;
		top: 0px;;
		margin-left: -2rem;
		background-color: #fff;
		width: 6rem;
		margin-left: -2rem;
		height: 4px;
		background-color: #ad8a3b;
	}
}

.title-after {
	@extend .text-left;
	position: relative;
	padding-bottom: 15px;

	&::after {
		content: '';
		position: absolute;
		left: 30px;
		bottom: 0px;;
		margin-left: -2rem;
		background-color: #ad8a3b;
		width: 3rem;
		margin-left: -2rem;
		height: 2px;
	}
}

.title-after-2 {
	position: relative;
	padding-bottom: 20px;
	margin-left: 10px;
	

	&::after {
		content: '';
		position: absolute;
		left: 30px;
		bottom: 0px;;
		margin-left: -2rem;
		background-color: white;
		width: 10px;
		margin-left: -2rem; 
		height: 2px;
		//border-radius: 50%;
	}
}

.title-after-3 {
	position: relative;
	padding-bottom: 0px;
	margin-left: 10px;
}

dl {
    margin-bottom: 2px;
}


.block-2{
	padding: 50px 0 50px 0;
	background-color: white;
	background: url(../images/charte/block2.jpg)  no-repeat center;
	background-size: cover;
	background-attachment: fixed;
	color: #ad8a3b;
	margin-bottom: 5px;
	margin-top: 50px;

	p{
		// font-family: 'Libre Franklin', sans-serif;
		padding-left: 25px;
		color: black;
	}
	.text-petit{
		padding-left: 0px;
		margin-top: 5px;
	}
	.col-md-6{
		padding: 0px;
	}
}

.block-2-container{
	margin-bottom: 50px;
}

.accessoire{
padding-bottom: 50px;

}

.img-1{
	margin: auto;
	max-width:300px;
	border: solid 3px #ad8a3b;
	margin-top: 5px;

	@media only screen and (max-width : 768px) {
		max-width:100%;
	}
}

.img-3{

	border: solid 3px #ad8a3b;

	@media only screen and (max-width : 768px) {
		max-width:100%;
	}
}

.img-2{
	margin: auto;
	max-width:400px;
	border: solid 3px #ad8a3b;
	margin-top: 5px;
	
	@media only screen and (max-width : 768px) {
		max-width:100%;

	}
	
}

.bis{

	margin-top: 35px;

}


.text-petit {
	font-size: 70%;
	p {
		padding-left: 0px;
	}
}


.white{
color: white;
}

.black{
	color: black;
}

.block-4{
	margin-bottom: 50px;
	color: white;
}

.block-5{
	padding: 25px 0 25px 0;
	background-color: white;
	background: url(../images/charte/block2.jpg)  no-repeat center;
	background-size: cover;
	background-attachment: fixed;
	color: #ad8a3b;
	margin-bottom: 5px;
	margin-top: 20px;

}

.logo-grid_logo {
    background: white;
}

.contact{
	a{
		color: white;
	}
}
	
.pop{

	margin-bottom: 50px;
	
	}