/* ==========================================================================
   Accueil
   ========================================================================== */

// .main-accueil {
//   min-height: 50vh;
// }



/* ==========================================================================
   Accueil
   ========================================================================== */

   .body-accueil{

      background: url(../images/charte/fond.jpg) no-repeat center;
      background-size: cover; 
      //background: white;
    
       h2{
        color: #ad8a3b;
    
       }
    } 
    
    .header-accueil {
      @extend .header;
      @extend .text-center;
    }
    
    .logo-accueil {
      display: inline-block;
      margin: 5rem auto 1rem auto;
    
      >img {
        @extend .img-responsive;
        display: inline-block;
        width: 70rem;
        max-width: 90%;
      }
    }
    
    .main-accueil {
      min-height: 100px;
    
      .h2{
        color: #fff;
      }
    }
    
    .btn-accueil {
      @extend .btn;
      @extend .btn-lg;
      @extend .btn-default;
      margin: $line-height-computed auto;
    }
    
    .footer-accueil {
      @extend .text-center;
      padding: 6rem 0;
      background-color: rgba(white, .8);
      border-top: solid 1px #ad8a3b;
    
      h1 {
        font-size: 2.5rem;
      }
    }
    
    .col-footer-accueil-left {
      @extend .col-sm-6;
      @extend .text-left;
      
      @media (max-width: $screen-xs-max) {
        text-align: center;
      }
    }
    
    .col-footer-accueil-right {
      @extend .col-sm-6;
      @extend .text-right;
      
      @media (max-width: $screen-xs-max) {
        text-align: center;
        padding-top: 20px;
      }
    }
    
    .btn-perso-1{
    background-color: white;
    border: solid 1px #ad8a3b;
    border-radius:45px;
    width: 300px;
    color: #ad8a3b;
    
    &:hover{
      background-color: #ad8a3b;
      border: solid 1px #ad8a3b;
      border-radius:45px;
      width: 300px;
      color: white;
    }
    }
    
    