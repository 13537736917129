/* ==========================================================================
	 header-basic
	 ========================================================================== */



	 
.header-basic {
	position: relative;
	//background: url(../images/charte/header.jpg) no-repeat center;
	background: url(../images/charte/fond.jpg) no-repeat center;
  background-size: cover; 
	//height: 30rem;
	
	.content-header {
		@extend .container-max;
		padding: 3rem;
	}
	
	.logo-header {
		
		>img {
			@extend .img-responsive;
			display: inline-block;
			//width: 300px;
		}
	}
}